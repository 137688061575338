import { css, CSSObject, SerializedStyles } from "@emotion/react";
import { Link } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { verticalMargin, verticalPadding } from "../../margins";
import { bpLarge, bpMedium, colorGoldStroke } from "../../stylingConstants";
import { Expand, Minimize } from "../Atoms/Icons";
import { captionTypeface } from "../Atoms/Text";
import { routeHelper } from "../../routeHelper";
import { useAwardDecades } from "../../hooks";

type Props = {
  selectedOption?: string;
  menuCss?: SerializedStyles | CSSObject;
};

export default function YearSelectMenu({ selectedOption, menuCss }: Props) {
  const decades = [...useAwardDecades()];
  const activeDecade = useMemo(() => selectedOption && decades.find(([_decade, years]) => years.indexOf(selectedOption) > -1)?.[0], [decades, selectedOption]);
  const [selectedDecade, selectDecade] = useState(activeDecade);
  useEffect(() => {
    selectDecade(activeDecade);
  }, [activeDecade]);
  const decadeOrder = [2, 1, 0, 9, 8, 7, 6, 5, 4, 3];

  const sortedData = decades.map(([decade, years]) => {
    return [decade, years.sort((a: any, b: any) => Number(b) - Number(a))];
  });

  const sortedByDecade = sortedData.sort((a: any, b: any) => {
    const aIndex = decadeOrder.indexOf(Number(a[0]));
    const bIndex = decadeOrder.indexOf(Number(b[0]));
    return aIndex - bIndex;
  });

  return (
    <ul
      css={[
        css`
          margin: 0;
          padding: 0;
          list-style: none;
          @media ${bpMedium} and (min-height: 570px) {
            position: sticky;
            top: 50px;
          }
          @media ${bpLarge} and (min-height: 630px) {
            position: sticky;
            top: 72px;
          }
        `,
        menuCss,
      ]}
    >
      {sortedByDecade.map(([decade, years]) => {
        const isSelectedDecade = selectedDecade === decade;
        const Icon = isSelectedDecade ? Minimize : Expand;
        return (
          <li
            key={decade}
            css={css`
              ${verticalMargin(8)}
            `}
          >
            <a
              onClick={() => selectDecade(!isSelectedDecade ? decade : undefined)}
              css={css`
                font-size: 17px;
                ${verticalPadding(4)}
                font-weight: 800;
                @media ${bpLarge} {
                  font-size: 19px;
                }
              `}
            >
              <Icon
                css={css`
                  position: relative;
                  top: 6px;
                  @media ${bpLarge} {
                    top: 5px;
                  }
                `}
              />
              <span css={{ marginLeft: 12 }}>{`${decade}0-talet`}</span>
            </a>
            <ul
              css={css`
                display: block;
                list-style: none;
                border-left: 2px solid ${colorGoldStroke};
                padding-left: 22px;
                margin-left: 9px;
                overflow: hidden;
                transition: height 250ms ease-in-out;
                height: ${isSelectedDecade ? 26 * years.length - 11 : 0}px;
                ${verticalMargin(isSelectedDecade ? 12 : 0)}
                @media ${bpLarge} {
                  height: ${isSelectedDecade ? 32 * years.length - 14 : 0}px;
                  ${verticalMargin(isSelectedDecade ? 16 : 0)}
                }
              `}
            >
              {years.map((year: any) => (
                <li
                  key={year}
                  css={css`
                    display: block;
                    opacity: ${isSelectedDecade ? 1 : 0};
                    transition: opacity ${isSelectedDecade ? 150 : 0}ms ease-out;
                    transition-delay: ${isSelectedDecade ? 250 : 0}ms;
                    font-family: ${captionTypeface};
                    font-weight: ${selectedOption === year ? 500 : 400};
                    font-size: 14px;
                    line-height: 14px;
                    ${verticalPadding(6)}
                    :first-of-type {
                      padding-top: 0;
                    }
                    :last-of-type {
                      padding-bottom: 0;
                    }
                    @media ${bpLarge} {
                      font-size: 16px;
                      line-height: 16px;
                      ${verticalPadding(8)}
                    }
                  `}
                >
                  <Link to={routeHelper.getYearRoute(parseInt(year))}>{year}</Link>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
